
.music-description {
  color: grey;
  font-family: "Helvetica Neue",sans-serif;
  font-size: 1.2rem;
  text-align: center;
}


@media only screen and (max-width: 991px) {
  .music-image {
    height: 20px;
    width: 20px;
    padding: 3px;
  }
}

.music-col {
  background-color: rgb(0,0,0,0.6);
  text-align: center;
}

.music-image {
  display: inline-block;
  height: 150px;
  width: 150px;
  padding: 20px;
  text-align: center;
}

.snippets {
  margin: 20px 0;
}

.typing {
  text-align: center;
}
