body {
  -webkit-transition: opacity 2s ease-in;
  -moz-transition: opacity 2s ease-in;
  -o-transition: opacity 2s ease-in;
  -ms-transition: opacity 2s ease-in;
  transition: opacity 2s ease-in;
}

.project{
    margin-top: 7%;
    margin-bottom: 10%;
}
.separate{
    background: rgb(0,0,0,0.4);
    padding: 5%;
}

.zoom {
    transition: transform .3s
}

.zoom:hover {
    transform: scale(1.05);
}

.section h3{
    color: #004085;
}
.section p{
    margin-top: 10%;
    color: #545b62;
}
.section img
{
    width: 100%;
}

.project-image {
  height: inherit;
  width: inherit;
}

.project-heading {
  font-family: "Helvetica Neue",sans-serif;
  color: white;
  font-size: 1.5rem;
  padding-top: 10px;
}

h2 {
  color: white;
}

.project-description {
  font-family: "Helvetica Neue",sans-serif;
  color: grey;
  font-size: 1.2rem;
}

.underline {
  border-color: white;
  border-style: solid;
}

.middle {
  vertical-align: middle;
}

.center {
  text-align: center;
}

a:link {
  color: white;
}

a:hover {
  color: grey;

}

a:visited {
  color: white;
}

#projects {
  background-color: rgb(0,0,0,0.2);
}
