h1 {
  font-family: "Helvetica Neue",sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  color: white;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.icon-row {
  margin: 20px;
}

.icon {
  color: white;
}

.section-heading {
  font-family: "Helvetica Neue",sans-serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.5;
  color: white;
  padding-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.card-box {
    background-color: rgb(0,0,0,0.2);
    min-height: 300px;
    position: relative;
    padding: 30px 50px 15px;
    margin-bottom: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    height: 100%;
    
    transform: rotateX(0) rotateY(0);
   transform-style: preserve-3d;
 transition-duration: 0.1s;
 transition-timing-function: ease !important;
 backface-visibility: hidden;
 will-change: tranform;
}


.card-box:hover {
    background: linear-gradient(to right, #1fa2ff17 0%, #12d8fa2b 51%, #1fa2ff36 100%);

}

.card-box:after {
    display: block;
    background: #2196F3;
    border-top: 2px solid #990000;
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.card-title h2 {
    margin: 0;
    padding-top: 5%;
    color: white;
    font-family: "Helvetica Neue",sans-serif;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 20px;
}

.card-title p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 16px;
    transform-style: preserve-3d;
}


.list {
  font-family: "Helvetica Neue",sans-serif;
  color: grey;
  font-size: 1.2rem;
}

#skills {
  padding: 7% 0;
}
