

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#changeText {
    opacity: 1;
    transition: opacity 0.5s;
}
.hide {
    opacity: 0 !important;
}

.bg-black {
  background-color: (0,0,0,0.1);
  width: inherit;
}

#home {
  margin-top: 80px;
}

.logo-font {
  font-family: "Helvetica Neue",sans-serif;
  font-weight: bold;
  font-size: 1.2em;
}

#experience {
  padding-top: 50px;
}

body {
  background-image: url("../assets/images/home_section_2.jpg");
  background-color: black;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
}

.big-heading {
  font-family: "Helvetica Neue",sans-serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.5;
  color: white;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.small-heading {
  font-family: "Helvetica Neue",sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  color: white;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.logo-fit {
  height: inherit;
  width: 12% ;
}

.profile-fit {
  height: 600px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  z-index: 1;
  position: block;
  padding: 10% 0;
}

.title {
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
  vertical-align: middle;
}

.social-icons {
  border-color: red;
  border-width: 2px;
}

.title-container {
  background-color: rgb(0,0,0,0.3);
}

.nav-text {
  font-family: "Helvetica Neue",sans-serif;
  font-size: 1.1em;
  font-weight: 500;
  font-style: bold;
}

.inline-dis {
  display: inline;
}

.row
{
  padding: 0 10% 0;
}

.icon {
  color: white;
  border-color: red;
}

.bt-portfolio {
  color: white;
  margin-top: 3%;
  border-radius: 40%;
}

.image-align {
  height: inherit;
  margin-top: auto;
  margin-bottom: auto;
}

.about {
  color: grey;
  font-size: 1.2rem;
}

.collapse {
  position: right;
}

@media only screen and (max-width: 1309px) {
  .profile-fit {
    height: 550px;
  }
}

@media only screen and (max-width: 1245px) {
  .profile-fit {
    height: 525px;
  }
}

@media only screen and (max-width: 1100px) {
  .profile-fit {
    height: 500px
  }
}

@media only screen and (max-width: 1200px) {
  .profile-fit {
    height: 515px;
  }
}

@media only screen and (max-width: 1769px) {
  .profile-fit {
    height: 575px;
  }
}

@media only screen and (max-width: 1023px) {
  .profile-fit {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .navbar {
    background-color: rgb(0,0,0);
  }
}

.navbar-brand {
  padding-left: 40px;
  float: none;
}

.navbar {
  z-index: 500;
}

.navbar-nav {
    padding-right: 40px;
    float: none;
}

.black {
  opacity: 1;
  background-color: black;
}

.navbar-toggler {
  display: inline-block;
}
.navbar-collapse.collapsing,
.navbar-collapse.show {
    padding-left: 40px;
}

.nav-item {
  text-align: center;
}

.navbar-toggler {
  margin-right: 40px;
}

.icon:hover {
    color: #75001b;
}
