.contact-description {
  font-family: "Helvetica Neue",sans-serif;
  text-align: center;
  color: grey;
  font-size: 1.2rem;
}

.pb-100 {
	padding-bottom: 100px;
}
.pt-100 {
	padding-top: 100px;
}
.mb-100 {
	margin-bottom: 100px;
}
a {
	text-decoration: none;
	color: #333;
	-webkit-transition: .4s;
	transition: .4s;
}
.section-title {
	position: relative;
  font-family: "Helvetica Neue",sans-serif;
}
.section-title p {
  font-family: "Helvetica Neue",sans-serif;
	font-size: 16px;
	margin-bottom: 5px;
	font-weight: 400;
}
.section-title h4 {
  font-family: "Helvetica Neue",sans-serif;
	font-size: 40px;
	font-weight: 600;
	text-transform: capitalize;
	position: relative;
	padding-bottom: 20px;
	display: inline-block;
}
.section-title h4::before {
	position: absolute;
	content: "";
	width: 80px;
	height: 2px;
	background-color: #d8d8d8;
	bottom: 0;
	left: 50%;
	margin-left: -40px;
}
.section-title h4::after {
	position: absolute;
	content: "";
	width: 50px;
	height: 2px;
	background-color: #FF7200;
	left: 0;
	bottom: 0;
	left: 50%;
	margin-left: -25px;
}
.contact {
  font-family: "Helvetica Neue",sans-serif;
	background-image: url("http://infinityflamesoft.com/html/abal-preview/assets/img/contact_back.jpg");
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	position: relative;
	z-index: 2;
	color: #fff;
}
.contact-form input,
textarea {
	width: 100%;
	border: 1px solid #555;
	padding: 5px 10px;
	text-transform: capitalize;
	margin-top: 15px;
	background-color: transparent;
	color: #fff;
}
.contact:before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: #333;
	z-index: -1;
	opacity: .85;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
}
.single-contact {
  font-family: "Helvetica Neue",sans-serif;
	text-align: left;
	position: relative;
	padding-left: 70px;
	margin-bottom: 50px;
	margin-top: 10px;
}
.single-contact i.fa {
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	background-color: #FF7200;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-radius: 3px;
}
.single-contact h5 {
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: 500;
  color: white;
  text-align: center;
}

.dis-block {
  display: block;
}

.single-contact p {
	font-size: 15px;
	font-weight: 400;
}
.contact-form input[type="submit"] {
	background-color: rgb(0,0,0,0.3);
	border: 0px;
	cursor: pointer;
	font-size: 16px;
	-webkit-transition: .4s;
	transition: .4s;
}
.contact-form input[type="submit"]:hover {
	background-color: #75001b
}
.contact-form input:focus, textarea:focus {
	border-color: #75001b
}

.footer-icon {
  line-height: 2;
}

.footer {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-top: 30px;
  background-color: rgb(0,0,0,0.5);
}

.footer-col {
  text-align: center;
  color: white;
  border-left: black;
  border-right: black;
  border-style: none solid;
}
